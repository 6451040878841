<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">模版管理</h2>

        <div class="hearSty">
          <div class="topBtn">
            <el-upload
                ref="upload"
                class="upload-demo"
                action
                :auto-upload="false"
                accept=".xlsx,.docx,.pptx,.doc,.xls,.ppt"
                :limit=1
                :before-upload="beforeUpload"
                :on-change="handleChange"
                :on-error="handleError"
                :file-list="file"
            >
              <el-button icon="el-icon-upload" type="primary">点击上传</el-button>
            </el-upload>
          </div>
          <div class="firDiv">
            <el-select v-model="fileTypeVal" clearable placeholder="请选择文件类型" @change="fileTypeChange(fileTypeVal)">
              <el-option v-for="item in fileTypeOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="firDiv">
            <el-input v-model="fileName" clearable placeholder="请输入文件名称" @keyup.enter.native="searchName" @blur="searchName" @clear="searchName"></el-input>
          </div>
        </div>

        <el-table :data="tableData" border center>
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="name" label="模版名称" align="center">
          </el-table-column>
          <el-table-column prop="type" label="类型" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template style="display: flex" slot-scope="scope">
              <el-button size="mini" type="primary" @click="open(scope.row)">预览</el-button>
              <el-button size="mini" type="danger" @click="deleTem(scope.row)">删除</el-button>
              <el-button size="mini" type="primary" @click="downFile(scope.row)">下载</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
        </el-pagination>
      </el-main>

      <el-dialog title="分享链接" :visible.sync="sharedialogVisible" width="450px" :before-close="sharedialogVisibleClose">
        <div>
          链接权限：
          <el-radio-group v-model="sharLink"  @change="handleLinkType">
            <el-radio :label="1">只读</el-radio>
            <el-radio :label="2">可编辑</el-radio>
          </el-radio-group>
          <div class="sharSty">
            <div>分享链接:</div>
             <div class="copyStye">
               <el-input id="put" v-model="sharVal"></el-input>
               <el-button type="primary" @click="copyLink">复制链接</el-button>
             </div>
          </div>
        </div>
      </el-dialog>


    </el-container>
  </el-container>
</template>

<script>
import _qs from "qs";
import Vue from "vue";
export default {
  data() {
    return {
      fileTypeVal:"",
      fileTypeOptions:[{id:'w',name:'文字文档'},{id:'s',name:'表格文档'},{id:'p',name:'演示文档'}],
      fileName:'',
      sharVal:'',
      sharLink:1,
      sharedialogVisible:false,
      file:[],
      reayName: "",
      // 学组管理的分页
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,
      // 表格数据
      tableData: [],
      taskId:""
    };
  },
  created() {
    this.fileMsgList();
  },
  methods: {
    fileTypeChange(val){
      this.pageNum = 1;
      this.fileTypeVal=val
      this.fileMsgList();
    },
    searchName(){
      this.pageNum = 1;
      this.fileMsgList();
    },
    // 复制
    copyLink(){
      let d = document.getElementById("put") //获取需要复制的元素
      d.select() //选中
      document.execCommand("copy") //直接复制
      this.$message.success('复制成功')
    },
    handleLinkType(){
      // this.sharVal='http://localhost:8080/weboffice.html'
    },
    sharedialogVisibleClose(){
      this.sharedialogVisible=false
    },
    async deleTem(row){
      const confirmResult = await this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);

      if (confirmResult !== "confirm") {
        return this.$message.info("已经取消删除");
      }
      let data = _qs.stringify({
        fileId: row.id,
      });
      let { data: res } = await this.$axios.deleteTemplateFile(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.fileMsgList();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 下载
    downFile(row){
      let data = _qs.stringify({
        fileId:row.id
      });

      this.$axios.downloadTemplateFile(data,row.name);

    },
    beforeUpload(file) {
      let extension = file.name.substring(file.name.lastIndexOf('.')+1)
      let size = file.size / 1024 / 1024;
      if(extension !== 'xlsx' && extension !== 'xls') {
        this.$message.warning('只能上传后缀是.xlsx或者.xls的文件')
        return false
      }
      if(size > 10) {
        this.$message.warning('文件大小不得超过10M')
        return false
      }
      return true
    },
    // 文件状态改变
    async handleChange(file, fileList) {
      if (file) {
        // console.log(file)
        this.file = fileList.slice(-3)

        // 可以根据后台接口要求来决定参数的类型
        this.formdata = new FormData()
        this.formdata.append('file', file.raw)

        let res = await this.$axios.uploadTemplate(this.formdata);
        // console.log(res)
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 200) {
          // console.log(res.data.data)
          this.$message({
            message: '上传成功',
            type: "success",
          });
          this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
          this.fileMsgList()
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      }
    },
    // 文件上传失败
    handleError(err, file, fileList) {
      this.$message.error('文件上传失败')
    },
    open(row){
      // console.log(row)

      let data={
        name:row.name,
        creatorId:row.id,
        type:row.name.split('.')[1],
        token: window.sessionStorage.getItem("userId")
      }

      //跳转并携带对象参数
      let encodedObject = encodeURIComponent(JSON.stringify(data));
      window.open(`/weboffice.html?data=` + encodedObject, '_blank');

    },
    change(e) {
      this.$forceUpdate(e);
    },
    // 学组管理分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.fileMsgList();
    },
    async fileMsgList() {
      let data = _qs.stringify({
        type:this.fileTypeVal,
        name:this.fileName,
        page: this.pageNum, //页数
        size: this.pageSize, //每页显示条数
      });
      let { data: res } = await this.$axios.fileTemplateList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-pagination{
  text-align: center;
}
/deep/ .el-dialog__body {
  text-align: inherit;
  .btnCen {
    button {
      width: 150px;
    }
  }
}

.btnDiv{
  text-align: center;
  margin-top: 20px;
  button {
    width: 150px;
  }
}

.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}
.depart-addnum {
  width: 100%;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}
.el-main {
  .table-title {
    display: flex;
    margin-bottom: 10px;
    .index-add {
      margin-left: 20px;
    }
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.text-ellipsis{
  width:380px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/ .el-textarea__inner {
  width: 220px !important;
}

/deep/ .el-input__inner {
  width: 220px !important;
}

.hearSty{
  display: flex;
  float: right;
  .firDiv,.topBtn{
    margin-left: 10px;
  }
}

.topBtn{
  margin-bottom: 20px;
  //float: right;
  display: flex;
  button{
    width: 120px;
    margin-left: 10px;
  }
}

.sharSty{
  display: flex;
  margin-top: 20px;
  align-items: center;
  .copyStye{
    display: flex;
    margin-left: 10px;
    .el-button{
      margin-left: 10px;
    }
  }
}

</style>
